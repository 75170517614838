
import ItemService from '../services/ItemService';

import RoleService from '../services/RoleService';
import LocalStorageService from '../services/LocalStorageService';
import { defineComponent } from 'vue';

interface ComponentData {
  activateNotification: boolean;
  typeNotification: 'success' | 'error';
  titleNotification: string;
  messageNotification: string;

  itemService: ItemService;
  pageid: number | string;
  itemImage: File;
  itemImageURL: string;
  title: string;
  videoId: string;
  isEditable: true;
  titleValid: boolean;
  videoValid: boolean;
  description: string;
  localStorageService: LocalStorageService;
  userName: string;
  roleService: RoleService;
  isactive: true;
  loading: boolean;
  videoErrorMsg: '';
  dropdownValue: { lable: string; value: boolean }[];
}

export default defineComponent({
  name: 'Videoupload',
  data() {
    const data: ComponentData = {
      activateNotification: false,
      typeNotification: 'success',
      titleNotification: '',
      messageNotification: '',

      itemService: undefined,
      pageid: '',
      itemImage: undefined,
      itemImageURL: '',
      title: '',
      videoId: '',
      isEditable: true,
      titleValid: false,
      videoValid: false,
      description: '',
      localStorageService: undefined,
      userName: undefined,
      roleService: undefined,
      isactive: true,
      loading: false,
      videoErrorMsg: '',
      dropdownValue: [
        { lable: 'Active', value: true },
        { lable: 'In Active', value: false },
      ],
    };
    return data;
  },
  created() {
    this.itemService = new ItemService();
    this.localStorageService = new LocalStorageService();
    this.userName = this.localStorageService.getItem('user-name');
    this.roleService = new RoleService(this.localStorageService);
    this.roleService.getRole();
  },
  async mounted() {
    this.videoId = this.$route.params.id;
    this.isEditable = this.videoId == 0;
    this.loading = true;
    if (!this.isEditable) {
      const video = await this.itemService.getVideoById(this.videoId);
      this.title = video.data.items[0].title;
      this.description = video.data.items[0].description;
      this.isactive = video.data.items[0].isActive;
    }
    this.loading = false;
  },
  methods: {
    onFileChanged() {
      const input: HTMLInputElement = this.$refs.formFile;
      const file = input.files;
      let filename: string;
      if (file) {
        this.itemImage = file[0];
        filename = file[0].name.toString().toLowerCase();
      }
      // await this.itemService.handleVideoUpload(this.itemImage);
    },

    async submitHandler() {
      this.titleValid = false;
      this.videoValid = false;
      if (this.title.trim() == '') {
        this.titleValid = true;
        return false;
      }
      this.loading = true;
      if (this.isEditable) {
        const input: HTMLInputElement = this.$refs.formFile;
        const file = input.files;

        if (file.length == 0) {
          this.loading = false;
          this.videoValid = true;
          this.videoErrorMsg = 'Please upload training video';
          return false;
        }

        if (file) {
          this.itemImage = file[0];
        }
        const filename = file[0].name.toString().toLowerCase();
        const ext = filename.split('.').pop();
        if (ext.toLocaleLowerCase() != 'mp4') {
          this.loading = false;
          this.videoValid = true;
          this.videoErrorMsg = 'Please Enter valid format';
          return false;
        }

        if (file[0].size > 104857600) {
          this.loading = false;
          this.videoValid = true;
          this.videoErrorMsg = 'File size more than 100 MB';
          return false;
        }

        const name = await this.itemService.handleVideoUpload(this.itemImage);
        const video = {
          title: this.title,
          description: this.description,
          createdby: this.userName,
          videopath: name.key,
          isActive: true,
        };
        await this.itemService.addVideoInDb(video, this);
        this.loading = false;
      } else {
        const video = {
          id: this.videoId,
          title: this.title,
          description: this.description,
          isActive: this.isactive,
        };
        await this.itemService.updateVideoInDb(video, this);
        this.loading = false;
      }
    },

    showNotifications(data: any) {
      this.activateNotification = data.activate;
      this.typeNotification = data.type;
      this.titleNotification = data.title;
      this.messageNotification = data.message;
    },
  },
});
